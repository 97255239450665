import { Trans } from '@lingui/macro';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MouseEvent, ReactNode } from 'react';

export type AlertDialogProps = Omit<DialogProps, 'title'> & {
  title: ReactNode;
  description: ReactNode;
  loading?: boolean;
  cancelText?: ReactNode;
  confirmText?: ReactNode;
  onConfirm?: () => void;
  hideActions?: boolean;
  invertActions?: boolean;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};

export function AlertDialog({
  title,
  description,
  loading,
  cancelText,
  confirmText,
  onConfirm,
  hideActions,
  invertActions,
  confirmButtonProps,
  cancelButtonProps,
  ...dialogProps
}: AlertDialogProps) {
  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    dialogProps.onClose?.(event, 'backdropClick');
  };
  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    onConfirm?.();
    handleClose(event);
  };

  return (
    <Dialog
      {...dialogProps}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      {!hideActions && (
        <DialogActions
          disableSpacing
          sx={{
            flexDirection: invertActions ? 'column-reverse' : 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            pb: 2
          }}
        >
          <LoadingButton
            loading={loading}
            {...(invertActions ? { variant: 'text', size: 'small' } : {})}
            {...confirmButtonProps}
            onClick={handleConfirm}
          >
            {confirmText || (
              <Trans id="common.alert.confirmText">Confirm</Trans>
            )}
          </LoadingButton>
          <Button
            autoFocus
            {...(invertActions ? {} : { variant: 'text', size: 'small' })}
            {...cancelButtonProps}
            onClick={handleClose}
          >
            {cancelText || <Trans id="common.alert.cencelText">Cancel</Trans>}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
