import useStableCallback from '@/hooks/use-stable-callback';
import { AnalyticsProps } from '@/types';
import { useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCurrentLocale } from '@/i18n';

type UseRecaptchaOptions = AnalyticsProps & {
  disabled?: boolean;
};

export function useRecaptcha({
  analyticsContext,
  analyticsParentId,
  disabled
}: UseRecaptchaOptions) {
  const googleRecaptchaSiteKey =
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';
  const isRecaptchaEnabled = !!googleRecaptchaSiteKey && !disabled;

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const recaptchaPromiseRef = useRef<{
    resolve: (token: string) => void;
    reject: (reason?: any) => void;
  }>();
  const locale = useCurrentLocale();

  const onChange = useCallback((token: string | null) => {
    if (token) {
      recaptchaPromiseRef.current?.resolve(token);
    } else {
      recaptchaPromiseRef.current?.reject(new Error('CAPTCHA_MISSING_ERROR'));
    }
    recaptchaRef.current?.reset();
  }, []);

  const executeRecaptcha = useStableCallback(() => {
    return new Promise<string>((resolve, reject) => {
      if (!isRecaptchaEnabled) {
        resolve('');
        return;
      }
      if (!recaptchaRef.current) {
        reject(new Error('CAPTCHA_MISSING_ERROR'));
        return;
      }

      rudderanalytics.track('Recaptcha Submit', {
        element_id: 'recaptcha',
        type: 'FORM',
        parent_id: analyticsParentId,
        context: {
          ...analyticsContext,
          is_enabled: isRecaptchaEnabled
        }
      });

      recaptchaPromiseRef.current = { resolve, reject };
      recaptchaRef.current.execute();
    });
  });

  const recaptchaElement = isRecaptchaEnabled ? (
    <ReCAPTCHA
      hl={locale}
      ref={recaptchaRef}
      size="invisible"
      onChange={onChange}
      sitekey={googleRecaptchaSiteKey}
      style={{ visibility: 'hidden' }}
    />
  ) : null;

  return {
    recaptchaRef,
    recaptchaElement,
    executeRecaptcha,
    isRecaptchaEnabled
  };
}
