import CloseOutlined from '@mui/icons-material/CloseOutlined';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

export function CloseButton(props: IconButtonProps) {
  return (
    <IconButton size="small" {...props}>
      <CloseOutlined />
    </IconButton>
  );
}
