import { Button, Skeleton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function PaymentMethodSkeleton() {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      bgcolor="background.default"
      borderRadius={1}
      paddingX={2}
      paddingY={1.5}
      gap={{ xs: 1, sm: 2, md: 3 }}
    >
      <Stack alignItems="center" spacing={2}>
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: 0.5 }}
          height={26}
          width={40}
        />
        <Typography fontWeight={500}>
          <Skeleton width={160} />
        </Typography>
      </Stack>
      <Stack flexGrow={1} alignItems="center">
        <Typography fontWeight={500}>
          <Skeleton width={120} />
        </Typography>
      </Stack>
      <Button sx={{ lineHeight: '20px' }} disabled variant="text">
        <Skeleton width={80} />
      </Button>
    </Box>
  );
}
