import { useCurrentLocale } from '@/i18n';
import { useCallback } from 'react';

export function useFormatCurrency() {
  const locale = useCurrentLocale();
  return useCallback(
    (input?: number | null, currency: string = 'USD') => {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      }).format(input || 0);
    },
    [locale]
  );
}
