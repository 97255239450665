'use client';

import { absoluteFill, centeredContent } from '@/utils/styles';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export type LoadingProps = CircularProgressProps & {
  fill?: boolean;
  message?: ReactNode;
};

// reference: https://mui.com/material-ui/react-progress/#customization
export function Loading({ fill, message, ...props }: LoadingProps) {
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        pointerEvents: 'none',
        ...centeredContent,
        ...(fill ? absoluteFill : {})
      }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          size={32}
          thickness={4}
          {...props}
          value={100}
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 900],
            ...props.sx
          }}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={32}
          thickness={4}
          {...props}
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            },
            ...props.sx
          }}
        />
      </Box>
      {message && <Typography>{message}</Typography>}
    </Stack>
  );
}
