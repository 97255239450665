import { StripePrice as StripePriceType } from '@/api/payments/types';
import { useFormatCurrency } from '@/hooks/use-format-currency';
import { Trans } from '@lingui/macro';
import { SxProps, Theme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { SystemStyleObject } from '@mui/system';

export type StripePriceProps = {
  quantity?: number;
  stripePrice?: Pick<
    StripePriceType,
    'amount' | 'currency' | 'interval'
  > | null;
  isLoading?: boolean;
  skeletonStyles?: SystemStyleObject<Theme>;
};

function getInterval(interval?: StripePriceType['interval']) {
  switch (interval) {
    case 'day':
      return <Trans id="payment.interval.day">day</Trans>;
    case 'week':
      return <Trans id="payment.interval.week">week</Trans>;
    case 'month':
      return <Trans id="payment.interval.month">month</Trans>;
    case 'year':
      return <Trans id="payment.interval.year">year</Trans>;
  }

  return <Trans id="payment.interval.oneTime">One Time</Trans>;
}

export function StripePrice({
  quantity,
  stripePrice,
  isLoading,
  skeletonStyles = {}
}: StripePriceProps) {
  const formatCurrency = useFormatCurrency();

  const formattedPrice = formatCurrency(
    ((stripePrice?.amount ?? 0) / 100) * (quantity || 1),
    stripePrice?.currency
  );

  const interval = getInterval(stripePrice?.interval);

  return isLoading ? (
    <Skeleton
      variant="text"
      sx={[{ maxWidth: 120, width: '100%' }, skeletonStyles]}
    />
  ) : (
    <>
      {formattedPrice} / {interval}
    </>
  );
}
