import { Offering } from '@/entitlements/types';
import { isFreeOffering } from '../../utils';
import { AnalyticsProps, Nullable } from '@/types';
import { StyledCheckbox } from '../../../../../../../components/styled-checkbox';
import { RenderHTML } from '@/libs/editor/html';
import Box from '@mui/material/Box';
import { ChangeEvent, useEffect } from 'react';

export type OfferingOrderBumpProps = AnalyticsProps & {
  offering: Offering;
  onSelect?: (selected: boolean) => void;
};

export function OfferingOrderBump({
  analyticsParentId,
  analyticsContext = {},
  offering,
  onSelect
}: OfferingOrderBumpProps) {
  useEffect(() => {
    rudderanalytics.track(
      'Element Visible',
      {
        element_id: 'order-bump',
        type: 'CARD',
        parent_id: analyticsParentId,
        offering_id: offering.id,
        content_name: offering.readableTitle,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          offering_id: offering.id,
          offering_name: offering.readableTitle,
          offering_type: isFreeOffering(offering) ? 'FREE' : 'PAID'
        }
      },
      analyticsContext.campaign_data
    );
  }, [analyticsContext, analyticsParentId, offering]);

  const handleCheckboxChange = (
    _event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    rudderanalytics.track(
      'Click',
      {
        element_id: 'order-bump-checkbox',
        type: 'CHECKBOX',
        parent_id: analyticsParentId,
        offering_id: offering.id,
        content_name: offering.readableTitle,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          offering_id: offering.id,
          offering_name: offering.readableTitle,
          offering_type: isFreeOffering(offering) ? 'FREE' : 'PAID',
          selected: isChecked
        }
      },
      analyticsContext.campaign_data
    );

    onSelect?.(isChecked);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1.5, sm: 2, md: 3 },
        maxWidth: 620,
        marginX: 'auto',
        p: 3,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'success.main',
        borderRadius: 1
      }}
    >
      {offering.description && <RenderHTML>{offering.description}</RenderHTML>}
      <StyledCheckbox label={offering.cta} onChange={handleCheckboxChange} />
    </Box>
  );
}
