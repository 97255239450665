import { Product } from '@/api/payments/types';
import { isMembershipProduct, isTeamOffering } from '@/api/purchase/utils';
import { StyledRadio } from '@/components/styled-radio';
import { OfferingMedia } from '@/entitlements/components/offerings/offering-media';
import { Offering } from '@/entitlements/types';
import useStableCallback from '@/hooks/use-stable-callback';
import { RenderHTML } from '@/libs/editor/html';
import { rudderanalyticsIdentify } from '@/libs/rudderstack';
import { AnalyticsProps } from '@/types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { campaignStore } from '../../store/campaign-store';
import { CampaignForm, CampaignFormInput } from '../campaign-form';

export type OfferingMainProps = AnalyticsProps & {
  userEmail?: string;
  offering: Offering;
  isOptedIn?: boolean;
  isSignedIn?: boolean;
  isFree: boolean;
  children?: ReactNode;
  formId: string;
  afterSubmit?: (
    stripePaymentMethodId: string,
    product: Product,
    quantity: number
  ) => void;
  handleOptIn?: () => void;
};

export function OfferingMain({
  userEmail,
  offering,
  isOptedIn,
  isFree,
  children,
  formId,
  afterSubmit,
  handleOptIn,
  analyticsParentId,
  analyticsContext = {}
}: OfferingMainProps) {
  const loading = campaignStore.useTracked.loading();
  const filteredProducts = useMemo(
    () =>
      offering?.products?.filter((offeringProduct) => offeringProduct.product),
    [offering]
  );

  const [selectedProduct, setSelectedProduct] = useState(
    filteredProducts?.[0]?.product
  );

  useEffect(() => {
    rudderanalytics.track(
      'Element Visible',
      {
        element_id: 'main-offering',
        type: 'CARD',
        parent_id: analyticsParentId,
        offering_id: analyticsContext.offering_id,
        content_name: analyticsContext.offering_name,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          products:
            filteredProducts?.map((item) => ({
              price_id: item.product.priceId,
              name: item.product.name,
              description: item.product.description,
              product_namespace: item.product.productNamespace,
              is_membership: isMembershipProduct(item.product)
            })) ?? []
        }
      },
      analyticsContext.campaign_data
    );
  }, [offering, isFree, analyticsContext, filteredProducts, analyticsParentId]);

  const handleProductSelect = (product: Product) => {
    rudderanalytics.track(
      'Click',
      {
        element_id: 'main-product-option',
        type: 'RADIO_BUTTONS',
        parent_id: analyticsParentId,
        offering_id: analyticsContext.offering_id,
        content_name: analyticsContext.offering_name,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          selected_product_name: product.name,
          selected_product_description: product.description,
          selected_product_namespace: product.productNamespace,
          selected_product_price_id: product.priceId,
          selected_product_is_membership: isMembershipProduct(product)
        }
      },
      analyticsContext.campaign_data
    );
    setSelectedProduct(product);
  };

  const handleClick = async () => {
    if (isOptedIn) {
      rudderanalytics.track(
        'Click',
        {
          element_id: 'main-offering-submit',
          type: 'BUTTON',
          parent_id: analyticsParentId,
          offering_id: analyticsContext.offering_id,
          content_name: analyticsContext.offering_name,
          campaign_id: analyticsContext.campaign_id,
          email: analyticsContext.email,
          context: {
            ...analyticsContext,
            selected_product_name: selectedProduct?.name,
            selected_product_description: selectedProduct?.description,
            selected_product_namespace: selectedProduct?.productNamespace,
            selected_product_price_id: selectedProduct?.priceId,
            selected_product_is_membership: isMembershipProduct(selectedProduct)
          }
        },
        analyticsContext.campaign_data
      );
    }
  };

  const trackFlowStartEvent = useStableCallback((inputEmail?: string) => {
    if (inputEmail) {
      rudderanalytics.track(
        'Campaign Flow Start',
        {
          element_id: 'campaign-flow',
          type: 'FLOW',
          parent_id: analyticsParentId,
          offering_id: analyticsContext.offering_id,
          content_name: analyticsContext.offering_name,
          campaign_id: analyticsContext.campaign_id,
          email: analyticsContext.email,
          context: {
            ...analyticsContext,
            email: inputEmail
          }
        },
        analyticsContext.campaign_data
      );
    }
  });

  useEffect(() => {
    trackFlowStartEvent(userEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackFlowStartEvent]);

  const trackOptInEvent = useStableCallback((email: string) => {
    rudderanalytics.track(
      'Email Opt-In',
      {
        element_id: 'email-open-in-button',
        type: 'FORM',
        parent_id: analyticsParentId,
        offering_id: analyticsContext.offering_id,
        content_name: analyticsContext.offering_name,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          email
        }
      },
      analyticsContext.campaign_data
    );

    rudderanalyticsIdentify({ email });
  });

  const handleSubmit = async (data: CampaignFormInput) => {
    // For logged in users, flow start is tracked at page load
    // For non-logged in users, for free campaigns, one step process, track flow start
    // For non-logged in users, for paid campaigns, two step process, track flow start if not opted in
    if (!userEmail && (isFree || !isOptedIn)) {
      trackFlowStartEvent(data.email);
    }

    // If not opted in, track opt in
    // If free campaign, one step process, track opt in
    // If logged in, one step process, track opt in
    if (!isOptedIn || isFree || userEmail) {
      trackOptInEvent(data.email);
    }

    if (!isOptedIn) {
      handleOptIn?.();
      return false;
    }

    return true;
  };

  const isTeam = isTeamOffering(offering);

  const formElement = (
    <>
      {(isOptedIn || isFree) && filteredProducts.length > 1 && (
        <Stack direction="column">
          {filteredProducts.map((offeringProduct) => (
            <StyledRadio
              checked={selectedProduct?.id === offeringProduct.product.id}
              value={offeringProduct.product.id}
              onChange={() => handleProductSelect(offeringProduct.product)}
              name={offering.id}
              key={offeringProduct.product.id}
              label={offeringProduct.product.name}
            />
          ))}
        </Stack>
      )}
      <CampaignForm
        priceId={selectedProduct?.priceId}
        isTeam={isTeam}
        hideSocialSignup
        // hideSocialSignup={!isFree && isOptedIn}
        analyticsParentId={analyticsParentId}
        analyticsContext={analyticsContext}
        email={userEmail}
        skipPaymentMethod={!isOptedIn || isFree}
        onSubmit={handleSubmit}
        afterSubmit={(stripePaymentMethodId, quantity) =>
          afterSubmit?.(stripePaymentMethodId, selectedProduct, quantity)
        }
        formId={formId}
      />
    </>
  );

  const ctaButton = (
    <LoadingButton
      id="main-offering-submit"
      size="large"
      form={formId}
      type="submit"
      loading={loading}
      onClick={handleClick}
    >
      {offering.cta}
    </LoadingButton>
  );

  const expanded = isOptedIn || isFree;

  return (
    <>
      <Collapse in={expanded} mountOnEnter unmountOnExit>
        <OfferingMedia
          id={offering.id}
          image={offering.image}
          heading={offering.heading}
          media={offering.media}
        />
      </Collapse>
      {isFree && (
        <>
          {formElement}
          {ctaButton}
        </>
      )}
      {offering.description && (
        <Collapse in={expanded} mountOnEnter unmountOnExit>
          <RenderHTML>{offering.description}</RenderHTML>
        </Collapse>
      )}
      {!isFree && formElement}
      {children && (
        <Collapse in={expanded} mountOnEnter unmountOnExit>
          {children}
        </Collapse>
      )}
      {!isFree && ctaButton}
    </>
  );
}
