import { I18n } from '@lingui/core';

export function getAuthErrorMessage({
  i18n,
  error,
  defaultMessage
}: {
  i18n: I18n;
  error: any;
  defaultMessage?: string;
}) {
  switch (error?.message) {
    case 'AutoSignInException':
      return i18n.t('auth-errors.auto-sign-in-exception');
    case 'InternalErrorException':
      return i18n.t('auth-errors.internal-error-exception');
    case 'TooManyRequestsException':
      return i18n.t('auth-errors.too-many-requests-exception');
    case 'UsernameExistsException':
      return i18n.t('auth-errors.account-exists-error');
    case 'UserNotConfirmedException':
      return i18n.t('auth-errors.user-not-confirmed-error');
    case 'UserNotFoundException':
      return i18n.t('auth-errors.user-not-found-error');
    case 'ForbiddenException':
      return i18n.t('auth-errors.forbidden-error');
    case 'NotAuthorizedException':
      return i18n.t('auth-errors.not-authorized-error');
    case 'PasswordResetRequiredException':
      return i18n.t('auth-errors.password-reset-required-error');
  }

  switch (true) {
    case error?.message.includes('CAPTCHA_MISSING_ERROR'):
      return i18n.t('auth-errors.captcha-missing-error');
    case error?.message.includes('CAPTCHA_VERIFICATION_ERROR'):
      return i18n.t('auth-errors.captcha-verification-error');
    case error?.message.includes('ACCOUNT_EXISTS_ERROR'):
      return i18n.t('auth-errors.account-exists-error');
    case error?.message.includes('Invalid phone number format'):
      return i18n.t('validations.invalid-phone-number');
    case error?.message.includes('Invalid email address format'):
      return i18n.t('validations.invalid-email');
    case error?.message.includes('Invalid password'):
    case error?.message.includes('Password did not conform with policy'):
      return i18n.t('validations.invalid-password');
  }

  return defaultMessage;
}
