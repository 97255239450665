import { AlertDialog } from '@/components/alert-dialog';
import { Loading } from '@/components/loading';
import { Trans } from '@lingui/macro';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Box from '@mui/material/Box';
import noop from 'lodash-es/noop';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';

export type PaymentProcessingDialogProps = {
  popupState: PopupState;
  isDone?: boolean;
};

export function PaymentProcessingDialog({
  popupState,
  isDone
}: PaymentProcessingDialogProps) {
  return (
    popupState.isOpen && (
      <AlertDialog
        {...bindDialog(popupState)}
        onClose={noop}
        hideActions
        title={
          <Box
            component="span"
            display="block"
            textAlign="center"
            sx={{ mt: 2 }}
          >
            {isDone ? (
              <TaskAltIcon color="success" fontSize="large" />
            ) : (
              <Loading />
            )}
          </Box>
        }
        description={
          <Box component="span" display="block" textAlign="center">
            {isDone ? (
              <Trans id="campaign.orderProcessed">
                Order processed successfully!
                <br />
                Redirecting...
              </Trans>
            ) : (
              <Trans id="campaign.orderProcessing">
                Processing your order...
              </Trans>
            )}
          </Box>
        }
      />
    )
  );
}
