import { useGraphqlClient } from '@/libs/amplify/client';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import {
  getCampaignQuery,
  getOfferingStackQuery,
  getOfferingStackUpsellPathQuery
} from './query-options';

export function useStreamingGetCampaign(id: string) {
  const graphqlClient = useGraphqlClient();

  return useSuspenseQuery(getCampaignQuery(graphqlClient, id));
}

export function useStreamingGetOfferingStack(campaignId: string) {
  const graphqlClient = useGraphqlClient();

  return useSuspenseQuery(getOfferingStackQuery(graphqlClient, campaignId));
}

export function useGetOfferingStack(campaignId: string) {
  const graphqlClient = useGraphqlClient();

  return useQuery(getOfferingStackQuery(graphqlClient, campaignId));
}

export function useGetOfferingStackUpsellPath(campaignId: string) {
  const graphqlClient = useGraphqlClient();

  return useQuery(getOfferingStackUpsellPathQuery(graphqlClient, campaignId));
}
