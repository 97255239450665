'use client';

import { AspectRatio } from '@/components/aspect-ratio';
// import { SocialSignupSkeleton } from '@/app/[lang]/(auth)/components/social-signup';
import { useAuthStore } from '@/stores/auth-store-provider';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

export function CampaignSkeleton() {
  const authStore = useAuthStore();
  const isSignedIn = authStore.useTracked.isSignedIn();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1, sm: 2 },
        maxWidth: 620,
        marginX: 'auto',
        width: '100%',
        pb: 4
      }}
    >
      <div>
        <Typography variant="body2" textAlign="center">
          <Skeleton
            sx={{ display: 'inline-block', maxWidth: '100%' }}
            variant="rounded"
            width={100}
          />
        </Typography>
        <Typography variant="h6" textAlign="center">
          <Skeleton
            sx={{ display: 'inline-block', maxWidth: '100%' }}
            variant="rounded"
            width={200}
          />
        </Typography>
      </div>

      {isSignedIn && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 1.5, sm: 2 }
          }}
        >
          <AspectRatio
            sx={{ maxWidth: 480, margin: 'auto', width: '100%' }}
            ratio={[16, 9]}
          >
            <Skeleton
              sx={{ width: '100%', height: '100%' }}
              variant="rounded"
            />
          </AspectRatio>
          <Box>
            <Typography variant="body2" textAlign="center">
              <Skeleton
                sx={{ display: 'inline-block', maxWidth: '100%' }}
                variant="rounded"
                width={220}
              />
            </Typography>
            <Typography textAlign="center">
              <Skeleton
                sx={{ display: 'inline-block', maxWidth: '100%' }}
                variant="rounded"
                width={80}
              />
            </Typography>
            <Typography textAlign="center">
              <Skeleton
                sx={{ display: 'inline-block', maxWidth: '100%' }}
                variant="rounded"
                width={400}
              />
            </Typography>
          </Box>
        </Box>
      )}
      {/*{!isSignedIn && <SocialSignupSkeleton />}*/}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 1.5, sm: 2, md: 3 }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
          }}
        >
          <FormLabel sx={{ fontSize: '0.875rem' }}>
            <Skeleton
              sx={{ display: 'inline-block', maxWidth: '100%' }}
              variant="rounded"
              width={90}
            />
          </FormLabel>
          <Skeleton variant="rounded" width="100%" height={56} />
        </Box>
        {isSignedIn && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5
            }}
          >
            <FormLabel sx={{ fontSize: '0.875rem' }}>
              <Skeleton
                sx={{ display: 'inline-block', maxWidth: '100%' }}
                variant="rounded"
                width={80}
              />
            </FormLabel>
            <Skeleton variant="rounded" width="100%" height={56} />
          </Box>
        )}
      </Box>

      {isSignedIn && <Skeleton variant="rounded" width="100%" height={300} />}
      <Skeleton
        variant="circular"
        width="100%"
        height={42}
        sx={{ borderRadius: '4rem' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="80%" />
      </Box>
    </Box>
  );
}
