'use client';

import { useFetchStripePrices } from '@/api/payments/client';
import { PaymentProcessingDialog } from '@/app/[lang]/(setup)/campaign/[slug]/components/payment-processing-dialog';
import { Offering } from '@/entitlements/types';
import { useCleanPathname } from '@/hooks/use-clean-pathname';
import useStableCallback from '@/hooks/use-stable-callback';
import { trackSignup } from '@/libs/firstpromoter';
import { useConfirmNavigation } from '@/providers/confirm-navigation-provider';
import { safeJsonParse } from '@/utils/safe-json';
import { useDynamicConfig } from '@statsig/react-bindings';
import compact from 'lodash-es/compact';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { OfferingMain } from './offerings/offering-main';
import { OfferingOrderBump } from './offerings/offering-order-bump';
import { useAuthStore } from '@/stores/auth-store-provider';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'usehooks-ts';
import { usePurchaseProducts } from '@/api/purchase';
import { isMembershipProduct } from '@/api/purchase/utils';
import { Product } from '@/api/payments/types';
import { campaignStore } from '../store/campaign-store';
import {
  skipOnboarding,
  isFreeOffering,
  getMarketingReferralCookie,
  campaignRedirectUrl,
  isTrialOffering
} from '../utils';
import { useRouter } from '@/hooks/use-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignOfferingStack } from '../api/types';
import {
  useGetOfferingStack,
  useGetOfferingStackUpsellPath,
  useStreamingGetCampaign
} from '../api/get';

export type CampaignPageContentProps = {
  slug: string;
};

const formId = 'campaign-form';
const analyticsParentId = 'Campaign Page';

export function CampaignPageContent({ slug }: CampaignPageContentProps) {
  const authStore = useAuthStore();
  const { data: campaign } = useStreamingGetCampaign(slug);
  const router = useRouter();
  const pathname = useCleanPathname();
  const user = authStore.useTracked.user();
  const isSignedIn = authStore.useTracked.isSignedIn();
  const { i18n } = useLingui();

  const offeringStack: CampaignOfferingStack | undefined =
    campaign?.offeringStack;
  const mainOffering: Offering | undefined = offeringStack?.offering;
  const orderBump: Offering | undefined = offeringStack?.orderBump;
  const isFree = isFreeOffering(mainOffering);
  const marketingData = getMarketingReferralCookie();
  const fetchStripePrices = useFetchStripePrices();
  const { value: dynamicConfigValue } = useDynamicConfig(
    'campaign_optimization_config'
  );

  const isLoading = campaignStore.useTracked.loading();
  const lodingPopupState = usePopupState({
    variant: 'dialog'
  });
  const { value: orderProcessed, setValue: setOrderProcessed } = useBoolean();

  const userEmail = user?.email;

  const analyticsContext: Record<string, any> = useMemo(
    () => ({
      ...(userEmail ? { email: userEmail } : {}),
      campaign_id: campaign?.id,
      offering_stack_id: campaign?.offeringStackId,
      offering_id: mainOffering?.id,
      offering_name: mainOffering?.readableTitle,
      offering_type: isFree ? 'FREE' : 'PAID',
      products:
        mainOffering?.products
          ?.filter((item) => item.product)
          ?.map((item) => ({
            price_id: item.product.priceId,
            name: item.product.name,
            description: item.product.description,
            product_namespace: item.product.productNamespace,
            is_membership: isMembershipProduct(item.product)
          })) ?? [],
      order_bump_id: orderBump?.id,
      campaign_data: safeJsonParse(marketingData) || {}
    }),
    [
      userEmail,
      campaign?.id,
      campaign?.offeringStackId,
      isFree,
      mainOffering?.id,
      mainOffering?.products,
      mainOffering?.readableTitle,
      marketingData,
      orderBump?.id
    ]
  );

  const purchaseProducts = usePurchaseProducts({
    analyticsParentId,
    analyticsContext,
    marketingData
  });
  const [isOrderBumpSelected, setIsOrderBumpSelected] = useState(false);

  const { value: isOptedIn, setTrue: setIsOptedIn } = useBoolean(
    isFree || isSignedIn
  );

  const message = i18n.t('confirm-navigation.message');

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return message;
    },
    [message]
  );

  const enableWindowConfirm = useStableCallback(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  });

  const disableWindowConfirm = useStableCallback(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  });

  useEffect(() => {
    rudderanalytics.track(
      'Campaign Flow Entry',
      {
        element_id: 'campaign-flow',
        type: 'FLOW',
        parent_id: analyticsParentId,
        offering_id: analyticsContext.offering_id,
        content_name: analyticsContext.offering_name,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: analyticsContext
      },
      analyticsContext.campaign_data
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFree) {
      setIsOptedIn();
    }
  }, [isFree, setIsOptedIn]);

  useEffect(() => {
    if (dynamicConfigValue.shouldConfirmBeforeExit) {
      enableWindowConfirm();
    }
  }, [dynamicConfigValue.shouldConfirmBeforeExit, enableWindowConfirm]);

  useEffect(() => {
    if (isLoading) {
      lodingPopupState.open();
    } else {
      lodingPopupState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // Lazy load upsells for speeding up upsells on next page
  useGetOfferingStack(slug);
  useGetOfferingStackUpsellPath(slug);

  const handleFinish = () => {
    rudderanalytics.track(
      'Campaign Flow Complete',
      {
        element_id: 'campaign-flow',
        type: 'FLOW',
        parent_id: analyticsParentId,
        offering_id: analyticsContext.offering_id,
        content_name: analyticsContext.offering_name,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          has_upsell: !!campaign?.offeringStack.upsell,
          has_order_bump: !!orderBump,
          order_bump_selected: isOrderBumpSelected,
          next_step: campaign?.offeringStack.upsell ? 'UPSELL' : 'HOME'
        }
      },
      analyticsContext.campaign_data
    );
    setOrderProcessed(true);
    disableWindowConfirm();
    if (campaign?.offeringStack.upsell) {
      router.replace(pathname + '/upsell');
    } else {
      const redirectUrl = campaignRedirectUrl(
        authStore.get.user(),
        mainOffering?.redirectUrl
      );
      router.replace(redirectUrl);
    }
  };

  const postPurchaseEvent = useStableCallback(
    async (orderBumpProduct: Product | undefined) => {
      if (isOrderBumpSelected && orderBump && orderBumpProduct) {
        const stripeIds = compact([
          !isTrialOffering(orderBump) ? orderBumpProduct?.priceId : null
        ]);
        const stripePriceIdsMap = await fetchStripePrices(stripeIds);
        const revenue = orderBumpProduct?.priceId
          ? (stripePriceIdsMap[orderBumpProduct.priceId]?.amount ?? 0)
          : 0;
        rudderanalytics.track(
          'Order Bump Purchase',
          {
            element_id: 'campaign-flow',
            type: 'FLOW',
            parent_id: analyticsParentId,
            offering_id: orderBump.id,
            content_name: orderBump.readableTitle,
            campaign_id: analyticsContext.campaign_id,
            revenue: !isNaN(revenue) ? revenue / 100 : 0,
            email: analyticsContext.email,
            context: analyticsContext
          },
          analyticsContext.campaign_data
        );
      }
    }
  );

  const handleAfterSubmit = async (
    stripePaymentMethodId: string,
    product: Product,
    quantity: number
  ) => {
    if (campaign && mainOffering) {
      try {
        campaignStore.set.loading(true);
        const orderBumpProduct = orderBump?.products?.find(
          (offeringProduct) => offeringProduct.product
        )?.product;

        await purchaseProducts({
          offeringProducts: [
            { offering: mainOffering, product, quantity },
            ...(isOrderBumpSelected && orderBump && orderBumpProduct
              ? [
                  {
                    offering: orderBump,
                    product: orderBumpProduct,
                    quantity: 1
                  }
                ]
              : [])
          ],
          campaignId: campaign.id,
          offeringStackId: campaign.offeringStackId,
          paymentMethodId: stripePaymentMethodId
        });

        postPurchaseEvent(orderBumpProduct);

        const latestUser = authStore.get.user();

        if (latestUser) {
          trackSignup(latestUser.email, {
            analyticsContext,
            analyticsParentId,
            analyticsElementId: 'campaign-submit-button'
          });
        }

        if (!isMembershipProduct(product)) {
          skipOnboarding();
        }

        handleFinish();
      } catch (e: any) {
        rudderanalytics.track(
          'Campaign Flow Error',
          {
            element_id: 'campaign-flow',
            type: 'FLOW',
            error: e.message,
            parent_id: analyticsParentId,
            offering_id: analyticsContext.offering_id,
            content_name: analyticsContext.offering_name,
            campaign_id: analyticsContext.campaign_id,
            email: analyticsContext.email,
            context: analyticsContext
          },
          analyticsContext.campaign_data
        );
        campaignStore.set.loading(false);
      }
    }
  };

  const handleLinkClick = (linkType: 'terms' | 'privacy') => {
    rudderanalytics.track(
      'Click',
      {
        element_id: `${linkType}-link`,
        type: 'BUTTON',
        parent_id: analyticsParentId,
        offering_id: analyticsContext.offering_id,
        content_name: analyticsContext.offering_name,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: analyticsContext
      },
      analyticsContext.campaign_data
    );
  };

  if (!mainOffering) {
    // TODO: 404 page
    return null;
  }

  const title = !isOptedIn ? (
    <Trans id="campaign.offering.whatsYourEmail">What&apos;s your email?</Trans>
  ) : isFree ? (
    <Trans id="campaign.offering.freeAccess">Get Free Access</Trans>
  ) : (
    <Trans id="campaign.offering.confirmOrder">Confirm your order</Trans>
  );

  const subtitle =
    !isOptedIn || isFree ? (
      <Trans id="campaign.offering.step1">Step 1</Trans>
    ) : (
      <Trans id="campaign.offering.step2">Step 2</Trans>
    );

  return (
    <>
      {!isFree &&
      dynamicConfigValue.showLoadingIndicator &&
      lodingPopupState.isOpen ? (
        <PaymentProcessingDialog
          popupState={lodingPopupState}
          isDone={orderProcessed}
        />
      ) : null}
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: 1, sm: 2 }}
        maxWidth={620}
        marginX="auto"
        pb={4}
      >
        <div>
          <Typography variant="body2" textAlign="center">
            {subtitle}
          </Typography>
          <Typography variant="h6" textAlign="center">
            {title}
          </Typography>
        </div>
        <OfferingMain
          userEmail={userEmail}
          analyticsParentId={analyticsParentId}
          analyticsContext={analyticsContext}
          isOptedIn={isOptedIn}
          offering={mainOffering}
          isFree={isFree}
          formId={formId}
          afterSubmit={handleAfterSubmit}
          handleOptIn={setIsOptedIn}
        >
          {orderBump && (
            <OfferingOrderBump
              analyticsParentId={analyticsParentId}
              analyticsContext={analyticsContext}
              offering={orderBump}
              onSelect={setIsOrderBumpSelected}
            />
          )}
        </OfferingMain>
        <Typography textAlign="center" color="text.secondary" variant="caption">
          {isFree ? (
            <Trans id="campaign.spam-warning-free">
              You&apos;re one step away from extraordinary growth in your life.
              Unsubscribe anytime. By registering, you agree to our{' '}
              <Link
                target="_blank"
                href="https://www.growthday.com/terms"
                aria-label={i18n.t('campaign.aria.terms-of-use')}
                onClick={() => handleLinkClick('terms')}
              >
                terms of use
              </Link>{' '}
              and{' '}
              <Link
                target="_blank"
                href="https://www.growthday.com/privacy"
                aria-label={i18n.t('campaign.aria.privacy-policy')}
                onClick={() => handleLinkClick('privacy')}
              >
                privacy policy
              </Link>
              .
            </Trans>
          ) : (
            <Trans id="campaign.spam-warning-paid">
              You&apos;re one step away from extraordinary growth in your life.
              Unsubscribe anytime. By registering, you agree to our{' '}
              <Link
                target="_blank"
                href="https://www.growthday.com/terms"
                aria-label={i18n.t('campaign.aria.terms-of-use')}
                onClick={() => handleLinkClick('terms')}
              >
                terms of use
              </Link>{' '}
              and{' '}
              <Link
                target="_blank"
                href="https://www.growthday.com/privacy"
                aria-label={i18n.t('campaign.aria.privacy-policy')}
                onClick={() => handleLinkClick('privacy')}
              >
                privacy policy
              </Link>
              . Prices may be subject to VAT and applicable taxes.
            </Trans>
          )}
        </Typography>
      </Box>
    </>
  );
}
