import { AlertProps, RadioProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import { ReactNode } from 'react';

export type StyledRadioProps = RadioProps & {
  label?: ReactNode;
  color?: AlertProps['severity'];
};

export function StyledRadio({
  label,
  color = 'success',
  ...props
}: StyledRadioProps) {
  return (
    <Alert
      component="label"
      icon={<Radio sx={{ margin: -1 }} {...props} />}
      severity={color}
      sx={{
        fontSize: '1rem',
        cursor: 'pointer',
        borderWidth: 1,
        borderColor: `${color}.main`,
        borderStyle: 'solid',
        color: 'text.primary'
      }}
    >
      {label}
    </Alert>
  );
}
