import { CSSProperties } from 'react';

export const absoluteFill: CSSProperties = {
  position: 'absolute',

  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  width: '100%',
  height: '100%'
};

export const centeredContent: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center'
};
