import type { Schema } from '@/schema';
import { SelectionSet } from 'aws-amplify/data';

export const getCampaignSelectionSet = [
  'id',
  'offeringStackId',

  // Main Offering
  'offeringStack.offering.id',
  'offeringStack.offering.readableTitle',
  'offeringStack.offering.type',
  'offeringStack.offering.redirectUrl',
  'offeringStack.offering.image.url',
  'offeringStack.offering.image.width',
  'offeringStack.offering.image.height',
  'offeringStack.offering.image.cdnId',
  'offeringStack.offering.media.locator',
  'offeringStack.offering.media.title',
  'offeringStack.offering.media.assetType',
  'offeringStack.offering.heading',
  'offeringStack.offering.description',
  'offeringStack.offering.cta',
  'offeringStack.offering.products.product.id',
  'offeringStack.offering.products.product.name',
  'offeringStack.offering.products.product.description',
  'offeringStack.offering.products.product.productNamespace',
  'offeringStack.offering.products.product.entitlements.identifier',
  'offeringStack.offering.products.product.billingInterval',
  'offeringStack.offering.products.product.priceId',
  'offeringStack.offering.products.product.subscriptionGroup',
  'offeringStack.offering.overrides.skipCreditCard',
  'offeringStack.offering.overrides.trialDuration',
  'offeringStack.offering.overrides.trialFrequency',

  // Order Bump
  'offeringStack.orderBump.id',
  'offeringStack.orderBump.readableTitle',
  'offeringStack.orderBump.type',
  'offeringStack.orderBump.redirectUrl',
  'offeringStack.orderBump.image.url',
  'offeringStack.orderBump.image.width',
  'offeringStack.orderBump.image.height',
  'offeringStack.orderBump.image.cdnId',
  'offeringStack.orderBump.media.locator',
  'offeringStack.orderBump.media.title',
  'offeringStack.orderBump.media.assetType',
  'offeringStack.orderBump.heading',
  'offeringStack.orderBump.description',
  'offeringStack.orderBump.cta',
  'offeringStack.orderBump.products.product.id',
  'offeringStack.orderBump.products.product.name',
  'offeringStack.orderBump.products.product.description',
  'offeringStack.orderBump.products.product.productNamespace',
  'offeringStack.orderBump.products.product.entitlements.identifier',
  'offeringStack.orderBump.products.product.billingInterval',
  'offeringStack.orderBump.products.product.priceId',
  'offeringStack.orderBump.products.product.subscriptionGroup',
  'offeringStack.orderBump.overrides.skipCreditCard',
  'offeringStack.orderBump.overrides.trialDuration',
  'offeringStack.orderBump.overrides.trialFrequency',

  // Upsell
  'offeringStack.upsell.id'
] as const;

export type Campaign = SelectionSet<
  Schema['Campaign']['type'],
  typeof getCampaignSelectionSet
>;

export type CampaignOfferingStack = Campaign['offeringStack'];

export type OfferingGql = Omit<
  CampaignOfferingStack['offering'],
  'products'
> & {
  products: {
    items: CampaignOfferingStack['offering']['products'];
  };
};

export type OfferingStack = {
  id: string;
  offering?: {
    redirectUrl?: string | null;
  };
  upsell?: {
    offering: OfferingGql;
  };
};

export type OfferingStackUpsellPath = {
  offering: OfferingGql;
  upsellYes?: OfferingStackUpsellPath | null;
  upsellNo?: OfferingStackUpsellPath | null;
};

export type OfferingStackUpsell = {
  upsell?: {
    upsellYes?: OfferingStackUpsellPath | null;
    upsellNo?: OfferingStackUpsellPath | null;
  };
};
