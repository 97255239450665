import { useListStripePrices } from '@/api/payments/client';
import { StripePrice } from '@/entitlements/components/stripe-price';
import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Card, CardContent, Chip, Link, Typography } from '@mui/material';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export type TeamsSeatInputFormValues = {
  seats?: number;
};

export type TeamsSeatInputProps = {
  priceId: string;
  readOnly?: boolean;
};

export function getTeamsSeatValidationSchema(i18n: I18n) {
  const schema: yup.ObjectSchema<TeamsSeatInputFormValues> = yup
    .object()
    .shape({
      seats: yup
        .number()
        .required(i18n.t('validations.required'))
        .min(
          10,
          i18n.t({
            id: 'teams.buy.min-seat',
            message: 'At least 10 seats required'
          })
        )
    });

  return schema;
}

export function TeamsSeatInput({ priceId, readOnly }: TeamsSeatInputProps) {
  const { i18n } = useLingui();
  const priceIdsMap = useListStripePrices([priceId]);
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body2" gutterBottom>
          <Trans id="settings.teams.buy.step-one">
            <strong>Step One:</strong> Choose how many people you want to
            purchase seats for:
          </Trans>
        </Typography>
        <Typography component="div" display="flex" alignItems="center">
          <Trans id="settings.teams.buy.price">
            <StripePrice
              stripePrice={priceIdsMap[priceId]?.data}
              isLoading={priceIdsMap[priceId]?.isLoading}
              skeletonStyles={{ display: 'inline-block', mr: 1 }}
            />{' '}
            per seat
          </Trans>
          <Chip
            color="secondary"
            sx={{
              borderRadius: 0.5,
              ml: 1,
              height: 'auto',
              fontWeight: 600
            }}
            size="small"
            label={i18n.t({
              id: `settings.teams.buy.teams-tag`,
              message: 'GrowthDay Teams'
            })}
          />
        </Typography>
        <Typography
          mb={1}
          display="block"
          variant="caption"
          color="text.secondary"
        >
          <Trans id="settings.teams.buy.applicable-taxes">
            plus applicable taxes
          </Trans>
        </Typography>
        <TextFieldElement
          InputProps={{ readOnly }}
          name="seats"
          type="number"
          fullWidth
          helperText={
            <Trans id="settings.teams.buy.helper-text">
              Questions? Reach out to us at{' '}
              <Link href="mailto:teams@growthday.com">teams@growthday.com</Link>
              .
            </Trans>
          }
          placeholder={i18n.t({
            id: 'settings.teams.buy.placeholder',
            message: 'Minimum 10'
          })}
          label={i18n.t({
            id: 'settings.teams.buy.label',
            message: 'Number of Seats'
          })}
        />
      </CardContent>
    </Card>
  );
}
