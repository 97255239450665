import { GrowthDayLogo } from '@/assets/logos/growthday';
import { CloseButton } from '@/components/close-button';
import { Theme, useMediaQuery } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { SignInForm, SignInFormProps } from './sign-in-form';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export type SignInDialogProps = SignInFormProps &
  Pick<DialogProps, 'onClose' | 'open'>;

export function SignInDialog({
  open,
  onClose,
  ...signInFormProps
}: SignInDialogProps) {
  const isMobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={isMobileView}
      {...{ open, onClose }}
    >
      <DialogTitle>
        <GrowthDayLogo />
      </DialogTitle>

      <CloseButton
        sx={{ position: 'absolute', top: 8, right: 8 }}
        onClick={() => onClose?.({}, 'backdropClick')}
      />

      <DialogContent>
        <SignInForm
          disableSocialSignup
          disableSignup
          ForgotPasswordProps={{ target: '_blank' }}
          {...signInFormProps}
        />
      </DialogContent>
    </Dialog>
  );
}
